<template>
  <ion-page>
    <ion-header>
      <MainToolbar :title="i18n.$t('track-infect.info.title')" />
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-list>
        <ion-item v-for="(item, index) in info" :key="index" detail="true" href="http://anirec.de">
          <ion-label class="ion-text-wrap">
            <ion-text v-if="item.title" class="title">
              <h2>{{ item.title }}</h2>
            </ion-text>
            <p v-if="item.summary">{{item.summary}}</p>
            <ion-text v-if="item.source" class="source">
              <p>Quelle: <i>{{item.source}}</i></p>
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent } from '@ionic/vue';

import MainToolbar from '@/components/MainToolbar.vue';

import { ref } from 'vue';

import { useI18n } from "@/utils/i18n";

export default  {
  name: 'Info',
  components: { IonHeader, IonContent, IonPage, MainToolbar },
  setup() {
    const i18n = useI18n();

    const info = ref([
      {
        title: 'Auf der folgenden Seite finden Sie wichtige Informationen zu EHV-1',
        source: 'http://example.com'
      },
      {
        title: 'Bei akuten Symptomen werden folgende Schritte empfohlen',
        summary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        source: 'http://example.com'
      },
      {
        title: 'Aktuelle Informationen zur Fallstatistik',
        summary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        source: 'http://example.com'
      }
    ]);

    return { i18n, info };
  }
}
</script>

<style scoped>
.title * {
  font-weight: bold;
}

.source > * {
  opacity: 0.5;
  font-size: 12px;
}
</style>
